<template>
  <div class="columns cards">
    <div class="column">
      <div class="card">
        <router-link :to="{name: routes.ROUTE_RECEIVING_EVENT}" class="card-content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <b-icon
            pack="fa"
            custom-class="fa-3x mb-4 fa-rotate-90"
            icon="sign-in-alt">
          </b-icon>
          <b class="is-size-5">
            Receiving Event
          </b>
        </router-link>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <router-link :to="{name: routes.ROUTE_DELIVERY_EVENT}" class="card-content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <b-icon
            pack="fa"
            custom-class="fa-3x mb-4 fa-rotate-270"
            icon="sign-out-alt">
          </b-icon>
          <b class="is-size-5">
            Delivery Event
          </b>
        </router-link>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <router-link :to="{name: routes.ROUTE_HISTORY}" class="card-content is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <b-icon
            pack="fa"
            custom-class="fa-3x mb-4"
            icon="history">
          </b-icon>
          <b class="is-size-5">
            History
          </b>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import * as routes from '../constants/router'
export default {
  name: 'Dashboard',
  data: () => {
    return {
      routes
    }
  }
}
</script>

<style lang="scss">
.cards{
  .icon{
    height: auto !important;
  }
}
</style>
