import Vue from 'vue'
import Vuex from 'vuex'
import * as mutation from '../constants/mutations'
import * as action from '../constants/actions'
import { USER_DATA } from '../constants/localStorage'
import { removeItemStorage, setItemStorage } from '../helpers/localStorage'
import router from '../router'
import { ROUTE_LOGIN, ROUTE_DASHBOARD } from '../constants/router'
import { ClientApi } from '../api/client'
import { ToastProgrammatic as Toast } from 'buefy'
import { CLEAN_HISTORY, SET_HISTORY } from '../constants/mutations'

Vue.use(Vuex)

const initUser = {
  token: null,
  companies: {}
}

export default new Vuex.Store({
  state: {
    user: {
      ...initUser
    },
    history: {
      deliveryEvent: [],
      receivingEvent: []
    }
  },
  mutations: {
    [mutation.SET_USER_DATA]: (state, data) => {
      state.user = data
      setItemStorage(USER_DATA, data)
    },
    [mutation.LOG_OUT_MUTATION]: state => {
      state.user = {
        ...initUser
      }
    },
    [mutation.CLEAN_HISTORY]: state => {
      state.history.deliveryEvent = []
      state.history.receivingEvent = []
    },
    [mutation.SET_HISTORY]: (state, payload) => {
      state.history[payload.type].push(payload.data)
    }
  },
  actions: {
    [action.LOG_OUT_ACTION]: (store, statusCode) => {
      store.commit(mutation.LOG_OUT_MUTATION)
      removeItemStorage(USER_DATA)
      Toast.open({
        duration: 5000,
        message: statusCode === 403 ? 'Session expired' : 'Logged out successfully!',
        position: 'is-bottom',
        type: statusCode === 403 ? 'is-danger' : 'is-success'
      })
      router.replace({ name: ROUTE_LOGIN })
    },
    [action.LOG_IN_ACTION]: async (store, form) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const request = new ClientApi()
        const response = await request.login(form)
        store.commit(mutation.SET_USER_DATA, response)
        Toast.open({
          duration: 5000,
          message: 'Logged in successfully!',
          position: 'is-bottom',
          type: 'is-success'
        })
        router.replace({ name: ROUTE_DASHBOARD })
      } catch (err) {
        throw err
      }
    },
    [action.GET_PARCELS_ACTION]: async (store) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const request = new ClientApi()
        const response = await request.getParcels(store.state.user.companies.items[0].customer_id)
        store.commit(CLEAN_HISTORY)
        response.data.forEach(item => {
          store.commit(SET_HISTORY, { type: item.payload.event, data: item })
        })
      } catch (err) {
        throw err
      }
    },
    [action.POST_EVENT_DELIVERY]: async (store, obj) => {
      const currentObj = JSON.parse(JSON.stringify(obj))

      if (currentObj.eventDetails.aggregationList.length > 0) {
        const indexesToRemove = []
        currentObj.eventDetails.aggregationList.forEach((palette, index) => {
          if (palette.skuList.length > 0) {
            const newArray = []
            for (let i = 0; i < palette.skuList.length; i++) {
              if (palette.skuList[i].value) {
                newArray.push(palette.skuList[i].value)
              }
            }
            palette.skuList = newArray
          }
          if (palette.skuList.length === 0) {
            if (!currentObj.eventDetails.palletList) {
              currentObj.eventDetails.palletList = []
            }
            indexesToRemove.push(index)
            currentObj.eventDetails.palletList.push(palette.ssccNumber)
          }
        })
        if (indexesToRemove.length === currentObj.eventDetails.aggregationList.length) {
          currentObj.eventDetails.aggregationList = []
        } else {
          indexesToRemove.reverse().forEach(index => {
            currentObj.eventDetails.aggregationList.splice(index, 1)
          })
        }
      }

      if (currentObj.eventDetails.skuList.length > 0) {
        const newArray = []
        for (let i = 0; i < currentObj.eventDetails.skuList.length; i++) {
          if (currentObj.eventDetails.skuList[i].value) {
            newArray.push(currentObj.eventDetails.skuList[i].value)
          }
        }
        currentObj.eventDetails.skuList = newArray
      }
      currentObj.data_validation = 'y'
      // eslint-disable-next-line no-useless-catch
      try {
        const request = new ClientApi()
        await request.deliveryEvent(store.state.user.companies.items[0].customer_id, currentObj)
      } catch (err) {
        throw err
      }
    },
    [action.POST_EVENT_RECEIVING]: async (store, obj) => {
      const currentObj = JSON.parse(JSON.stringify(obj))

      if (currentObj.eventDetails.skuList.length > 0) {
        const newArray = []
        for (let i = 0; i < currentObj.eventDetails.skuList.length; i++) {
          if (currentObj.eventDetails.skuList[i].value) {
            newArray.push(currentObj.eventDetails.skuList[i].value)
          }
        }
        currentObj.eventDetails.skuList = newArray
      }

      if (currentObj.eventDetails.palettList.length > 0) {
        const newArray = []
        for (let i = 0; i < currentObj.eventDetails.palettList.length; i++) {
          if (currentObj.eventDetails.palettList[i].value) {
            newArray.push(currentObj.eventDetails.palettList[i].value)
          }
        }
        currentObj.eventDetails.palettList = newArray
      }
      // eslint-disable-next-line no-useless-catch
      try {
        const request = new ClientApi()
        await request.receivingEvent(store.state.user.companies.items[0].customer_id, currentObj)
      } catch (err) {
        throw err
      }
    }
  },
  modules: {
  }
})
