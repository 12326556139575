<template>
  <StaticContent title="About us">
    <p>
      <b>Information according to § 5 Telemediengesetz (TMG):</b><br>
      <br>
      Josera petfood GmbH & Co. KG<br>
      Industriegebiet Süd<br>
      D-63924 Kleinheubach<br>
      <br>
      contact:<br>
      Tel.: +49 (0) 9371 940-0<br>
      Fax: +49 (0) 9371 940-149<br>
      E-mail: <a href="mailto:josera@josera.de">josera@josera.de</a><br>
      <br>
      Register court: Aschaffenburg Local Court<br>
      Registration number: HRA 5614<br>
      Sales tax identification number according to § 27a Umsatzsteuergesetz (UStG): DE 305 344 385<br>
      <br>
      <b>Personally liable partner of Josera petfood GmbH & Co. KG:</b><br>
      Josera petfood GmbH & Co. KG<br>
      Industriegebiet Süd<br>
      63924 Kleinheubach<br>
      <br>
      <b>Managing Director of Josera petfood GmbH & Co. KG:</b><br>
      Stephan Hoose<br>
      <br>
      <b>Register entry:</b><br>
      Register court: Aschaffenburg Local Court<br>
      Register number: HRB 13637<br>
      <br>
      <b>Competent supervisory authority:</b><br>
      Government of Upper Bavaria<br>
      Bavarian feed monitoring authority<br>
      Field 56<br>
      Maximilianstrasse 39<br>
      80538 Munich<br>
      <br>
      <b>Responsible for the content according to § 55 Abs. 2 Rundfunkstaatsvertrag (RStV):</b><br>
      Stefan Seitz<br>
      Industriegebiet Süd<br>
      63924 Kleinheubach<br>
      <br>
      <b>Disclaimer</b><br>
      <br>
      <b>Liability for contents</b><br>
      As a service provider, we are responsible for our own content on these pages in accordance with § 7 Para. 1 of the German Telemedia Act (TMG). According to §§ 8 to 10 TMG, however, we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this respect is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove the relevant content immediately.<br>
      <br>
      <b>Liability for links</b><br>
      Our website contains links to external websites of third parties over whose content we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognisable at the time of linking. However, permanent monitoring of the content of the linked pages is not reasonable without concrete indications of a legal violation. If we become aware of any infringements of the law, we will remove such links immediately.<br>
      <br>
      <b>Copyright</b><br>
      The contents and works on these pages created by the site operators are subject to German copyright law. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is marked as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.<br>
      <br>
      <b>Arbitration board</b><br>
      Online dispute resolution according to Art. 14 para. 1 ODR-Regulation: The European Commission provides a platform for online dispute resolution (OS):ec.europa.eu/consumers/odr/.
      You can find our e-mail address in the imprint above. We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.
    </p>
  </StaticContent>
</template>

<script>
import StaticContent from '../../components/pages/StaticContent'
export default {
  name: 'AboutUs',
  components: {
    StaticContent
  }
}
</script>
