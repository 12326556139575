import axios from 'axios'
import config from '../../config'
import { consoleLogAllResponseWhenDev, createAuthorizationHeader, sessionExpiredLogout } from './interceptor'
import * as api from '../constants/api'

const url = config.url

export class ClientApi {
  constructor () {
    this.request = axios.create({
      baseURL: url
    })
    consoleLogAllResponseWhenDev(this.request)
    sessionExpiredLogout(this.request)
  }

  login (form) {
    return new Promise((resolve, reject) => {
      this.request.post(api.API_LOGIN.url, form).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  getParcels (dealerId) {
    createAuthorizationHeader(this.request)
    return new Promise((resolve, reject) => {
      this.request.get(api.API_GET_PARCELS.url(dealerId)).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  deliveryEvent (dealerId, obj) {
    createAuthorizationHeader(this.request)
    return new Promise((resolve, reject) => {
      this.request.post(api.API_POST_DELIVERY_EVENT.url(dealerId), obj).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err.response)
      })
    })
  }

  receivingEvent (dealerId, obj) {
    createAuthorizationHeader(this.request)
    return new Promise((resolve, reject) => {
      this.request.post(api.API_POST_RECEIVING_EVENT.url(dealerId), obj).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err.response)
      })
    })
  }
}
