<template>
  <ValidationProvider
    :vid="vid"
    :name="label"
    :rules="rules"
    ref="validationWrapper"
    v-slot="{ errors, valid }"
  >
    <b-field
      :label="label"
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0] || isInvalid, '': valid}"
      :message="errors">
      <b-select :placeholder="placeholder" @change="resetValidationStatus" v-bind="$attrs" v-model="innerValue">
        <option :value="null" disabled selected>Pick ISO</option>
        <option v-for="(option, index) in optionList" :value="option.Code" v-bind:key="index">{{option.Name}}</option>
      </b-select>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm.js'

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String
    },
    placeholder: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    },
    label: {
      type: String
    },
    optionList: {
      type: Array
    }
  },
  data: () => ({
    innerValue: '',
    isInvalid: false
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal)
      this.blurInput()
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
      this.blurInput()
    }
  },
  methods: {
    blurInput () {
      this.$nextTick(() => {
        this.$refs.validationWrapper.validate()
      })
    },
    validate () {
      this.$nextTick(() => {
        this.$refs.validationWrapper.validate()
      })
    },
    checkIfValid () {
      this.isInvalid = true
    },
    resetValidationStatus () {
      this.isInvalid = false
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
