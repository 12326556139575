<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    ref="validationWrapper"
    v-slot="{ errors, valid }"
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0] || isInvalid, '': valid}"
      :message="errors"
      :placeholder="placeholder"
    >
      <b-input @blur="resetValidationStatus" ref="inputElement" v-model="innerValue" v-bind="$attrs" :maxlength="maxlength" :placeholder="placeholderInput"></b-input>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { extend, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm.js'

extend('paletteNumber', {
  validate: (str) => {
    return /^\(00\).+/.test(str)
  },
  message: 'This is not a valid Palette/SSCC Number. Example format: (00)XXXXXXXXXXXXX'
})

extend('serialNumber', {
  validate: (str) => {
    return /^\(01\).+/.test(str)
  },
  message: 'This is not a valid field. Example format: (01)XXXXXXXXXXXXX'
})

extend('singlePackage', {
  validate: (str) => {
    return /^\(21\).+/.test(str)
  },
  message: 'This is not a valid field. Example format: (21)XXXXXXXXXXXXX'
})

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String
    },
    placeholder: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    maxlength: {
      type: String,
      default: null
    },
    placeholderInput: {
      type: String,
      default: null
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: '',
    isInvalid: false
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal)
      this.blurInput()
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
      this.blurInput()
    }
  },
  methods: {
    blurInput () {
      this.$nextTick(() => {
        this.$refs.validationWrapper.validate()
      })
    },
    validate () {
      this.$nextTick(() => {
        this.$refs.validationWrapper.validate()
      })
    },
    checkIfValid () {
      this.isInvalid = true
    },
    resetValidationStatus () {
      this.isInvalid = false
      const paletteNameInput = document.querySelector('input[p-key="paletteSSCCInputModal"]')
      paletteNameInput && paletteNameInput.classList.remove('is-danger')
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
