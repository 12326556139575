<template>
  <div class="mb-4" ref="wrapper">
    <validate-input
      :label="label + (index + 1)"
      v-for="(element, index) in dataArray"
      ref="validateInputMultiple"
      v-bind:key="index"
      v-model="element.value"
      :rules="rules + (index === 0 && firstRequired ? '|required' : '')"
      v-on:input="e => {inputMultipleChanged(e, index, dataArray);}"
      :placeholder-input="placeholderInput"
      maxlength="100"
    />
  </div>
</template>

<script>
import ValidateInput from './ValidateInput'
import highlightInvalidInputs from '../helpers/invalidInputHighlighter.js'
const model = {
  value: ''
}

export default {
  name: 'InputMultiple',
  props: ['label', 'dataArray', 'placeholder', 'rules', 'firstRequired', 'placeholderInput', 'errors'],
  components: {
    ValidateInput
  },
  data: () => {
    return {
      placeHolderLocal: ''
    }
  },
  created () {
    this.placeHolderLocal = this.placeholder ? this.placeholder : 'value'
  },
  mounted () {
    if (this.errors) {
      const element = document.querySelector('input[p-key="paletteSSCCInputModal"]')
      highlightInvalidInputs(this.errors, [...this.$refs.validateInputMultiple, element])
    }
  },
  methods: {
    blurInputs () {
      this.$refs.validateInputMultiple.forEach(input => {
        input.validate()
      })
    },
    inputMultipleChanged (e, index, arr) {
      if (e && e.length > 0 && arr.length === index + 1) {
        arr.push({ ...model })
      }
    }
  }
}
</script>
