<template>
  <div class="header mb-4">
    <div class="is-flex is-justify-content-center p-4 is-relative">
      <a href="#" @click="goToMainPage" class="is-flex is-align-items-flex-end is-flex-direction-column">
        <img src="@/assets/logo.png" alt="" class="logo">
        <span class="has-text-primary has-text-weight-semibold">Tracking Client</span>
      </a>
    </div>
  </div>

</template>

<script>

import { goToCurrentHomepage } from '../helpers/router'

export default {
  name: 'Header',
  methods: {
    goToMainPage (e) {
      e.preventDefault()
      goToCurrentHomepage()
    }
  }
}
</script>
