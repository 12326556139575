import store from '@/store'
import { responseHandler } from '../helpers/dev'
import { LOG_OUT_ACTION } from '../constants/actions'

export const createAuthorizationHeader = request => {
  request.interceptors.request.use(config => {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + store.state.user.token
    }
    return config
  })
}

// export const refreshToken = request => {
//   request.interceptors.response.use(
//     function (response) {
//       return response
//     },
//     async function (error) {
//       const originalRequest = error.config
//       if (store.state.user.refresh_token && error.response.status && error.response.status === 401 && originalRequest.url !== refreshTokenUrl.url) {
//         try {
//           const requestNewToken = new ClientApi()
//           const response = await requestNewToken.refreshToken(store.state.user.refresh_token)
//           await store.commit('userToken:set', response)
//           setItemStorage(USER_DATA, response)
//           return request(originalRequest)
//         } catch (err) {
//           if (err.config.url === refreshTokenUrl.url && err.status === 401) {
//             store.dispatch(LOG_OUT_ACTION)
//           }
//           // eslint-disable-next-line no-throw-literal
//           throw 'Authorization error.'
//         }
//       } else {
//         return Promise.reject(error)
//       }
//     }
//   )
// }

export const sessionExpiredLogout = request => {
  request.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {
      if (error.response.status === 403 && error.response.data.message === 'Session expired') {
        store.dispatch(LOG_OUT_ACTION, 403)
        // eslint-disable-next-line no-throw-literal
        throw 'Authorization error.'
      }
      return Promise.reject(error)
    }
  )
}

export const consoleLogAllResponseWhenDev = request => {
  request.interceptors.response.use(
    function (response) {
      responseHandler(response)
      return response
    },
    function (error) {
      responseHandler(error.response)
      return Promise.reject(error)
    }
  )
}
