import { getItemStorage } from '../helpers/localStorage'
import { USER_DATA } from '../constants/localStorage'
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '../constants/router'
import { ToastProgrammatic as Toast } from 'buefy'

export const requireAuth = (to, from, next) => {
  const userData = getItemStorage(USER_DATA)
  if (userData) {
    return next()
  }
  Toast.open({
    duration: 5000,
    message: 'You must be logged in to view this part of the site!',
    position: 'is-bottom',
    type: 'is-danger'
  })
  return next({
    name: ROUTE_LOGIN
  })
}

export const onlyNotLogged = (to, from, next) => {
  const userData = getItemStorage(USER_DATA)
  if (!userData) {
    return next()
  }
  return next({
    name: ROUTE_DASHBOARD
  })
}
