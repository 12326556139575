<template>
  <div class="container">
    <div>
      <nav class="navbar navbar-main" role="navigation" aria-label="main navigation">
        <div class="navbar-brand"><a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
                                     data-target="mainNavbar"> <span aria-hidden="true"></span> <span
          aria-hidden="true"></span> <span aria-hidden="true"></span> </a></div>
        <div id="mainNavbar" class="navbar-menu">
          <div class="navbar-start">
            <a href="#" @click="hp" class="navbar-item"> <i class="fa-home fas"></i> </a>
          </div>
          <div class="navbar-end">
            <div class="navbar-item"> Logged as: <b class="ml-1"> {{this.username}}</b></div>
            <div class="navbar-item">
              <div class="buttons">
                <button @click="logOut" class="button is-primary"> Log out </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { goToCurrentHomepage } from '../helpers/router'
import { LOG_OUT_ACTION } from '../constants/actions'

export default {
  name: 'UserHeader',
  data: () => {
    return {
      username: null
    }
  },
  mounted () {
    this.username = this.$store.state.user.companies.items[0].name
  },
  methods: {
    hp (e) {
      e.preventDefault()
      goToCurrentHomepage()
    },
    logOut () {
      this.$store.dispatch(LOG_OUT_ACTION)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar{
  margin-bottom: 1rem;
}
</style>
