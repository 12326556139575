<template>
  <div>
    <div v-if="$store.state.history.receivingEvent.length <= 0" class="has-text-centered">
      No history for Receiving Event!
    </div>
    <table v-if="$store.state.history.receivingEvent.length > 0" class="table">
      <thead>
      <tr>
        <th style="width: 120px;">Date</th>
        <th style="width: 200px;">Delivery receipt number</th>
        <th>Sender</th>
        <th>Palette ID</th>
        <th>SGTINS</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in $store.state.history.receivingEvent" v-bind:key="index">
          <td>{{ formatDate(item.payload.eventDetails.eventTime) }}</td>
          <td>{{ item.payload.eventDetails.deliverySlipNo ? item.payload.eventDetails.deliverySlipNo : '-' }}</td>
          <td>{{ item.payload.senderName ? item.payload.senderName : '-' }}</td>
          <td>
            <span v-for="(paletteId, index) in item.payload.eventDetails.palettList" v-bind:key="'palette-'+index">
              {{ paletteId }}
              <br>
            </span>
          </td>
          <td>
            <button type="button" class="button is-primary is-small" href="#" title="Show all" @click="openModal(item.payload.eventDetails.skuList)">Show all</button>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal v-model="isModalOpen" :width="760" scroll="keep" :on-cancel="closeModal" :can-cancel="['x', 'escape', 'outside']">
      <div class="card" v-if="skuArray">
        <div class="card-content">
          <h2>Sgtins in:</h2>
          <ul>
            <li v-for="(item, index) in skuArray" v-bind:key="'skuList-'+index">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { formatDate } from '../../helpers/date'

export default {
  name: 'ReceivingHistory',
  data: () => {
    return {
      isModalOpen: false,
      skuArray: []
    }
  },
  methods: {
    formatDate (date) {
      return formatDate(date)
    },
    openModal (array) {
      this.skuArray = array
      this.isModalOpen = true
    },
    closeModal () {
      this.skuArray = []
    }
  }
}
</script>
