<template>
  <StaticContent title="404">
    <div class="is-flex is-justify-content-center is-align-content-center">
      <span class="is-size-4 has-text-black">Page not found</span>
    </div>
  </StaticContent>
</template>

<script>
import StaticContent from '../components/pages/StaticContent'
export default {
  name: 'Page404',
  components: {
    StaticContent
  }
}
</script>
