<template>
  <EventsLayout title="Delivery Event" icon-classes="fa-sign-out-alt fa-rotate-270" class="is-relative">
    <form action="">
      <h2>I would like to display the shipment of the following delivery:</h2>
      <validate-input
        label="Customer ID"
        ref="customerNameInput"
        v-model="form.eventDetails.customer"
        rules="required"
        maxlength="50"
      />
      <b-field label="Customer SAP ID">
        <b-input type="text" v-model="form.eventDetails.customerSapId" maxlength="32"></b-input>
      </b-field>
      <b-field label="Customer delivery note">
        <b-input maxlength="200" type="textarea"></b-input>
      </b-field>
      <validate-select
        label="Ship to country"
        ref="countryIso"
        v-model="form.eventDetails.customerShipTo"
        rules="required"
        placeholder="Pick ISO"
        :option-list="countries"
      />
      <h2>The delivery consists of:</h2>
      <DragAndDrop class="mb-4" @fileread="text => handleFileRead(text)"/>
      <b-field>
        <b-checkbox v-model="isSku">Identify by single SKUs:</b-checkbox>
      </b-field>
      <div class="field" v-if="isSku">
        <InputMultiple rules="" label="Enter SKU:" :dataArray="form.eventDetails.skuList" placeholder="Enter SKU" placeholder-input="XXXXXXXXXXXXX" ref="skuInputs" :first-required="isSku" />
      </div>
      <b-field>
        <b-checkbox v-model="isPalette">Identify by Palette ID or SSCC Number:</b-checkbox>
      </b-field>
      <div v-show="isPalette">
        <table v-if="form.eventDetails.aggregationList.length > 0">
          <tr v-for="(palette, index) in form.eventDetails.aggregationList" v-bind:key="palette.ssccNumber" ref="paletteRow" class="table-row" :class="{invalidPallete: isInvalid}" :pkey="palette.ssccNumber">
            <td width="30">{{index + 1}}</td>
            <td>
              <div style="display: flex;align-items: center">
                <b>Palette: </b>&nbsp;{{ palette.ssccNumber }}
                <b-field v-if="palette.isNew" class="ml-1">
                  <b-tag rounded type="is-info">New</b-tag>
                </b-field>
              </div>
              <div v-if="palette.isNew">
                <b>Bag(s) on the pallet:</b> {{ getItemsCounter(palette.skuList) }}
              </div>
            </td>
            <td class="has-text-right">
              <b-button type="is-success"
                        @click="editPalette(index)"
                        icon-pack="fas"
                        icon-right="edit"
                        :index="index" />
              <b-button type="is-danger"
                        @click="deletePalette(index)"
                        icon-pack="fas"
                        icon-right="trash" />
            </td>
          </tr>
        </table>
        <button type="button" class="button is-success add-new-palette" @click="openModal">
          Add palette to delivery
        </button>
        <span class="help is-danger mb-3" v-show="form.eventDetails.aggregationList.length === 0">Palette/SSCC is required!</span>
      </div>
      <span class="help is-danger mb-3" v-show="!isSku && !isPalette">SKU or Palette/SSCC list is required!</span>
      <div class="is-flex is-align-items-center is-justify-content-space-between actions">
        <div class="buttons mb-0">
          <button type="button" @click="submit" class="button is-link">Next step</button>
          <b-button type="is-ghost" @click="goBack">Cancel</b-button>
        </div>
      </div>
    </form>

    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>

    <b-modal v-model="isPaletteModalActive" :width="760" scroll="keep" :can-cancel="['x']" :on-cancel="cleanCurrentForm">
      <div class="card" v-if="currentPaletteForm">
        <div class="card-content">
          <template v-if="pickType">
            <div class="pick-type">
              <span class="title is-4">
                Are you adding a self-assembled or already pre-assembled (unchanged) palette?
              </span>
              <section>
                <div class="buttons">
                  <b-button type="is-primary" @click="handlePaletteType('new')">Self-assembled</b-button>
                  <b-button type="is-success" @click="handlePaletteType('exisitng')">Pre-assembled</b-button>
                </div>
              </section>
            </div>
          </template>
          <template v-else>
            <div class="mb-4">
              <span class="title is-4">
                {{ textByTypePicked() }}
              </span>
            </div>
<!--            <section class="mb-4">-->
<!--              <div class="block">-->
<!--                <b-radio v-model="currentPaletteForm.isNew"-->
<!--                         name="name"-->
<!--                         :native-value="true">-->
<!--                  New-->
<!--                </b-radio>-->
<!--                <b-radio v-model="currentPaletteForm.isNew"-->
<!--                         name="name"-->
<!--                         :native-value="false">-->
<!--                  Existing-->
<!--                </b-radio>-->
<!--              </div>-->
<!--            </section>-->
            <validate-input
              ref="paletteSSCCInputModal"
              label="Enter the ID or SSCC Number the palette is carrying"
              v-model="currentPaletteForm.ssccNumber"
              rules="required"
              placeholder-input="XXXXXXXXXXXXX"
              maxlength="32"
              p-key="paletteSSCCInputModal"
            />
            <label class="label mt-4">Enter the SGTINS (serialization numbers) of the bags you are putting on the palette</label>
            <InputMultiple rules="" label="SGTIN: " :dataArray="currentPaletteForm.skuList" placeholder="Enter SGTIN" placeholder-input="XXXXXXXXXXXXX" :errors="errorMessageFromApi" :first-required="currentPaletteForm.isNew" ref="modalSkuInputs"/>
            <div class="buttons">
              <b-button type="is-primary" @click="handlePalette">Save</b-button>
              <b-button type="is-ghost" @click="cancelModal">Cancel</b-button>
            </div>
          </template>
        </div>
      </div>
    </b-modal>

    <ApiError :errorMessageFromApi="errorMessageFromApi" />
  </EventsLayout>
</template>

<script>
import highlightInvalidInputs from '../helpers/invalidInputHighlighter.js'
import EventsLayout from '../components/pages/EventsLayout'
import { countries } from '../constants/countries'
import DragAndDrop from '../components/DragAndDrop'
import { ROUTE_SUCCESS } from '../constants/router'
import InputMultiple from '../components/InputMultiple'
import { POST_EVENT_DELIVERY } from '../constants/actions'
import { getISODate, getTimeOffset } from '../helpers/date'
import ValidateInput from '../components/ValidateInput'
import ApiError from '../components/ApiError'
import { handleMultipleValues, sortItemsInFile } from '../helpers/dataParse'
import ValidateSelect from '../components/ValidateSelect'

const sigtinSkuModel = {
  value: ''
}

// eslint-disable-next-line no-unused-vars
const paletteFormModel = {
  ssccNumber: null,
  isNew: false,
  skuList: [{ ...sigtinSkuModel }]
}

export default {
  name: 'DeliveryEvent',
  components: {
    EventsLayout,
    DragAndDrop,
    InputMultiple,
    ValidateInput,
    ApiError,
    ValidateSelect
  },
  data: () => {
    return {
      isInvalid: false,
      countries,
      isPaletteModalActive: false,
      isSku: true,
      isPalette: false,
      editIndex: null,
      errorMessageFromApi: null,
      currentPaletteFormSigtinsNumber: 0,
      currentPaletteForm: { ...JSON.parse(JSON.stringify(paletteFormModel)) },
      currentPaletteFormErrors: {
        ...JSON.parse(JSON.stringify(paletteFormModel))
      },
      loading: false,
      pickType: true,
      form: {
        deliverySlipNo: '',
        dealerCountryIso: '',
        deliveryCustomerName: '',
        event: 'deliveryEvent',
        eventDetails: {
          customer: '',
          customerSapId: '',
          customerShipTo: null,
          skuList: [{ ...JSON.parse(JSON.stringify(sigtinSkuModel)) }],
          aggregationList: [],
          eventTime: null,
          eventTimeZoneOffset: null
        }
      },
      isPopupEdited: false,
      currentPopupIndex: ''
    }
  },
  computed: {
    isEditing () {
      return typeof this.editIndex === 'number'
    }
  },
  mounted () {
    this.form.deliverySlipNo = this.$store.state.user.companies.items[0].customer_id
    this.form.dealerCountryIso = this.$store.state.user.companies.items[0].company_country
    this.form.deliveryCustomerName = this.$store.state.user.companies.items[0].name
  },
  methods: {
    setPalleteToInvalid () {
      this.isInvalid = true
    },
    setPalleteToValid () {
      this.isInvalid = false
    },
    textByTypePicked () {
      if (this.currentPaletteForm.isNew && !this.isEditing) { // nowa nowa
        return 'Assemble your palette'
      } else if (!this.currentPaletteForm.isNew && !this.isEditing) { // nowa egzystujaca
        return 'Add a pre-assembled (unchanged) palette'
      } else if (this.currentPaletteForm.isNew && this.isEditing) { // edycja nowej
        return 'Edit self-assembled palette'
      } else if (!this.currentPaletteForm.isNew && this.isEditing) { // edycja egzystujacej
        return 'Edit a pre-assembled (unchanged) palette'
      }
    },
    getItemsCounter (arr) {
      let counter = 0
      arr.forEach(item => {
        if (item.value.length > 0) {
          counter += 1
        }
      })
      return counter
    },
    handleFileRead (arr) {
      const sorted = sortItemsInFile(arr)
      if (sorted[0].length > 0) {
        handleMultipleValues(sorted[0], this.form.eventDetails.skuList)
        this.isSku = true
      }
      if (sorted[1].length > 0) {
        // const paletteFormModel = {
        //   ssccNumber: null,
        //   isNew: false,
        //   skuList: [{ ...sigtinSkuModel }]
        // }
        sorted[1].forEach(item => {
          if (!item.length > 0) {
            return
          }
          const model = JSON.parse(JSON.stringify(paletteFormModel))
          model.ssccNumber = item
          model.id = Math.random()
          this.form.eventDetails.aggregationList.push(model)
        })
        this.isPalette = true
      }
    },
    isValid () {
      let errors = false

      if (this.form.eventDetails.customer.length <= 0) {
        window.scrollTo(0, 0)
        this.$refs.customerNameInput.validate()
        errors = true
      }
      if (this.isSku && this.form.eventDetails.skuList[0].value.length === 0) {
        this.$refs.skuInputs.blurInputs()
        errors = true
      }

      if (!this.isSku && !this.isPalette) {
        errors = true
      }

      if (!this.form.eventDetails.customerShipTo) {
        this.$refs.countryIso.blurInput()
        errors = true
      }

      if (this.isPalette && this.form.eventDetails.aggregationList.length === 0) {
        errors = true
      }

      return !errors
    },
    submit () {
      if (!this.isValid()) {
        return
      }
      if (!this.form.eventDetails.skuList[0].value && this.form.eventDetails.aggregationList.length <= 0) {
        return this.$buefy.toast.open({
          duration: 5000,
          message: 'SKU list or Palettes list is required!',
          position: 'is-bottom',
          type: 'is-danger'
        })
      }

      // if (this.form.eventDetails.skuList.length > 0) {
      //   let notMatch = false
      //   this.form.eventDetails.skuList.forEach(item => {
      //     if (item.value && !(/^\(01\).+/.test(item.value)) && !notMatch) {
      //       notMatch = true
      //     }
      //   })
      //
      //   if (notMatch) {
      //     return
      //   }
      // }
      const highLightedValues = document.querySelectorAll('.error-row')
      highLightedValues.forEach(higlightedValue => {
        higlightedValue.classList.remove('error-row')
      })
      this.sendEvent()
    },
    async sendEvent () {
      const currentDate = new Date()
      this.errorMessageFromApi = null
      this.form.eventDetails.eventTime = getISODate(currentDate)
      this.form.eventDetails.eventTimeZoneOffset = getTimeOffset(currentDate)
      try {
        this.loading = true
        const obj = JSON.parse(JSON.stringify(this.form))
        if (!this.isSku) {
          obj.eventDetails.skuList = [{ ...JSON.parse(JSON.stringify(sigtinSkuModel)) }]
        }
        if (!this.isPalette) {
          obj.eventDetails.aggregationList = []
        }
        await this.$store.dispatch(POST_EVENT_DELIVERY, obj)
        this.$router.replace({ name: ROUTE_SUCCESS, params: { eventType: 'Delivery' } })
      } catch (err) {
        if (err && err.status === 400) {
          this.errorMessageFromApi = err.data.error
          // highlight erros in palettes
          const palleteArrayKeys = []
          const palleteArrayValues = []

          const paletteArray = this.form.eventDetails.aggregationList
          paletteArray.forEach(pallete => {
            palleteArrayKeys.push(pallete.ssccNumber)
            palleteArrayValues.push(pallete.skuList)
          })
          // create map
          const palleteMap = new Map()
          palleteArrayKeys.forEach((key, i) => {
            palleteMap.set(palleteArrayKeys[i], palleteArrayValues[i])
          })
          // get plain errors array
          const errorMessageString = err.data.error
          const errorsMessgeStringWithoutText = errorMessageString.substring(errorMessageString.indexOf(':') + 1).replace(/ /g, '')
          const erorrsTable = errorsMessgeStringWithoutText.split('/')
          // find error tab
          // eslint-disable-next-line no-unused-vars
          for (const [key, values] of palleteMap.entries()) {
            if (erorrsTable.includes(key)) {
              document.querySelector(`[pkey="${key}"]`).classList.add('error-row')
            }
            values.forEach((content) => {
              if (erorrsTable.includes(content.value)) {
                document.querySelector(`[pkey="${key}"]`).classList.add('error-row')
              }
            })
          }
          try {
            highlightInvalidInputs(this.errorMessageFromApi, this.$refs.skuInputs.$children)
          } catch (error) {
            return
          }
          return
        }
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Something went wrong... Please try again later.',
          position: 'is-bottom',
          type: 'is-danger'
        })
      } finally {
        this.loading = false
      }
    },
    cleanCurrentForm () {
      this.currentPaletteForm = { ...JSON.parse(JSON.stringify(paletteFormModel)) }
      this.currentPaletteFormErrors = { ...JSON.parse(JSON.stringify(paletteFormModel)) }
      this.currentPaletteForm.skuList = [{ ...JSON.parse(JSON.stringify(sigtinSkuModel)) }]
      this.editIndex = null
    },
    openModal () {
      this.pickType = true
      this.isPaletteModalActive = true
    },
    cancelModal () {
      this.isPaletteModalActive = false
      this.cleanCurrentForm()
    },
    validateFormModal () {
      this.$refs.paletteSSCCInputModal.blurInput()
      let errors = false

      if (this.currentPaletteForm.isNew && this.currentPaletteForm.skuList[0].value.length === 0) {
        this.$refs.modalSkuInputs.blurInputs()
        errors = true
      }

      if (!this.currentPaletteForm.ssccNumber) {
        errors = true
        return
      }
      const notMatch = false

      // this.currentPaletteForm.skuList.forEach(item => {
      //   if (item.value && !(/^\(01\).+/.test(item.value)) && !notMatch) {
      //     notMatch = true
      //   }
      // })

      if (notMatch) {
        errors = true
        return !errors
      }

      if (this.form.eventDetails.aggregationList.length > 0) {
        const currentPaletteId = this.currentPaletteForm.ssccNumber

        for (let i = 0; i < this.form.eventDetails.aggregationList.length; i++) {
          // eslint-disable-next-line no-mixed-operators
          if (
            // eslint-disable-next-line no-mixed-operators
            (this.form.eventDetails.aggregationList[i].ssccNumber === currentPaletteId && this.editIndex !== i && typeof this.editIndex === 'number') ||
            // eslint-disable-next-line no-mixed-operators
            this.form.eventDetails.aggregationList[i].ssccNumber === currentPaletteId && typeof this.editIndex !== 'number') {
            this.$buefy.toast.open({
              duration: 3000,
              position: 'is-bottom',
              message: 'This ssccNumber field should be unique!',
              type: 'is-danger'
            })
            errors = true
            break
          }
        }
      }

      return !errors
    },
    handlePalette (index) {
      if (!this.validateFormModal()) {
        return
      }
      if (typeof this.editIndex !== 'number') {
        this.form.eventDetails.aggregationList.push(this.currentPaletteForm)
      } else {
        this.form.eventDetails.aggregationList[index] = this.currentPaletteForm
      }
      this.cleanCurrentForm()
      this.isPaletteModalActive = false
      const stringText = this.isPopupEdited === true ? 'Edited' : 'Added new'
      const properFormOfMessage = this.isPopupEdited === true ? 'on list!' : 'to list!'
      const modifiedPaletteNumber = this.currentPopupNumber
      const lastCreatedPaletteNumber = this.form.eventDetails.aggregationList[this.form.eventDetails.aggregationList.length - 1].ssccNumber
      const numberOfPaletteToDisplay = modifiedPaletteNumber === -999 ? lastCreatedPaletteNumber : modifiedPaletteNumber

      this.$buefy.toast.open({
        duration: 3000,
        position: 'is-bottom',
        message: `${stringText} palette "${numberOfPaletteToDisplay}" ${properFormOfMessage}`,
        type: 'is-success'
      })
      this.editIndex = null
    },
    editPalette (index) {
      this.isPaletteModalActive = true
      this.currentPaletteForm = this.form.eventDetails.aggregationList[index]
      this.pickType = false
      this.editIndex = index
      this.$refs.paletteRow[index].classList.remove('error-row')
      this.isPopupEdited = true
      this.currentPopupNumber = this.form.eventDetails.aggregationList[index].ssccNumber
    },
    deletePalette (index) {
      this.form.eventDetails.aggregationList.splice(index, 1)
    },
    goBack () {
      this.$router.back()
    },
    handlePaletteType (type) {
      switch (type) {
        case 'new':
          this.currentPaletteForm.isNew = true
          break
        case 'exisiting':
          this.currentPaletteForm.isNew = false
          break
      }
      this.pickType = false
      this.isPopupEdited = false
      this.currentPopupNumber = -999
    }
  }
}
</script>
<style lang="scss">

  .pick-type{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .error-row {
    border: 2px solid red;
  }
</style>
