export default function highlightInvalidInputs (errorMessage, mapRefElements) {
  // check if errors occurs
  getCleanErrorArray(errorMessage).forEach(error => {
    for (const [key, value] of createMapOfInputsAndInsertedValues(mapRefElements).entries()) {
      if (error === value) {
        const inputValidation = key.checkIfValid
        if (inputValidation) {
          inputValidation()
        } else {
          key.classList.add('is-danger')
        }
      }
    }
  })
}

// get clean array with errors
function getCleanErrorArray (errorMessage) {
  const errorMessageString = errorMessage
  const errorsMessgeStringWithoutText = errorMessageString.substring(errorMessageString.indexOf(':') + 1).replace(/ /g, '')
  return errorsMessgeStringWithoutText.split('/')
}

// create map of inputs with inserted values
function createMapOfInputsAndInsertedValues (refElements) {
  const skuInputsArray = refElements
  const skuMapKeys = []
  const skuMapValues = []

  skuInputsArray.forEach(element => {
    skuMapKeys.push(element)
    skuMapValues.push(element.value)
  })

  const skuMap = new Map()
  skuMapKeys.forEach((key, i) => {
    skuMap.set(skuMapKeys[i], skuMapValues[i])
  })

  return skuMap
}
