import Vue from 'vue'
import VueRouter from 'vue-router'
import InitScreen from '../views/InitScreen'
import Login from '../views/Login'
import Page404 from '../views/Page404'
import TermsAndCondtitions from '../views/Static/TermsAndCondtitions'
import Dashboard from '../views/Dashboard'
import History from '../views/History'
import DeliveryHistory from '../views/History/Delivery'
import ReceivingHistory from '../views/History/Receiving'
import * as constRoutes from '../constants/router'
import { requireAuth, onlyNotLogged } from './auth'
import DeliveryEvent from '../views/DeliveryEvent'
import PreviewEvent from '../views/PreviewEvent'
import Success from '../views/Success'
import ReceivingEvent from '../views/ReceivingEvent'
import AboutUs from '../views/Static/AboutUs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: constRoutes.ROUTE_INIT_SCREEN,
    component: InitScreen
  },
  {
    path: '/login',
    name: constRoutes.ROUTE_LOGIN,
    beforeEnter: onlyNotLogged,
    component: Login
  },
  {
    path: '*',
    name: constRoutes.ROUTE_404,
    component: Page404
  },
  {
    path: '/terms-and-conditions',
    name: constRoutes.ROUTE_TERMS_AND_CONDITIONS,
    component: TermsAndCondtitions
  },
  {
    path: '/about-us',
    name: constRoutes.ROUTE_ABOUT_US,
    component: AboutUs
  },
  {
    path: '/dashboard',
    name: constRoutes.ROUTE_DASHBOARD,
    beforeEnter: requireAuth,
    component: Dashboard
  },
  {
    path: '/history',
    name: constRoutes.ROUTE_HISTORY,
    beforeEnter: requireAuth,
    component: History,
    children: [
      {
        path: 'receiving',
        name: constRoutes.ROUTE_HISTORY_RECEIVING,
        beforeEnter: requireAuth,
        component: ReceivingHistory
      },
      {
        path: 'delivery',
        name: constRoutes.ROUTE_HISTORY_DELIVERY,
        beforeEnter: requireAuth,
        component: DeliveryHistory
      }
    ]
  },
  {
    path: '/delivery-event',
    name: constRoutes.ROUTE_DELIVERY_EVENT,
    beforeEnter: requireAuth,
    component: DeliveryEvent
  },
  {
    path: '/receiving-event',
    name: constRoutes.ROUTE_RECEIVING_EVENT,
    beforeEnter: requireAuth,
    component: ReceivingEvent
  },
  {
    path: '/success',
    name: constRoutes.ROUTE_SUCCESS,
    beforeEnter: requireAuth,
    props: true,
    component: Success
  },
  {
    path: '/preview-event',
    name: constRoutes.ROUTE_PREVIEW,
    beforeEnter: requireAuth,
    component: PreviewEvent
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
