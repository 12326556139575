<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-5-desktop is-3-widescreen">
            <div class="box">
              <b-field label="Email">
                <b-input placeholder="Email"
                         v-model="form.username"
                         type="email"
                         icon-pack="fas"
                         icon="envelope">
                </b-input>
              </b-field>
              <b-field label="Password">
                <b-input placeholder="***** ***"
                         v-model="form.password"
                         type="password"
                         icon-pack="fas"
                         password-reveal
                         @keyup.native="enterHandler"
                         icon="lock">
                </b-input>
              </b-field>
              <div class="field">
                <b-button type="is-primary login-button" :disabled="loading" @click="login">
                  <span v-show="!loading">Login</span>
                  <b-icon
                    v-show="loading"
                    pack="fas"
                    icon="sync-alt"
                    custom-class="fa-spin">
                  </b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { LOG_IN_ACTION } from '../constants/actions'
export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      form: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    async login () {
      if (!this.form.username || !this.form.password) {
        return
      }
      try {
        this.loading = true
        await this.$store.dispatch(LOG_IN_ACTION, this.form)
      } catch (err) {
        if (err && err.status === 403) {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Wrong email/password!',
            position: 'is-bottom',
            type: 'is-danger'
          })
        } else {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Something went wrong... Please try again later.',
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
      } finally {
        this.loading = false
      }
    },
    enterHandler (e) {
      if (e.key === 'Enter') {
        this.login()
      }
    }
  }
}
</script>

<style lang="scss">
.login-button{
  .icon{
    margin-left: 0 !important;
    margin-right: 0!important;
  }
}
</style>
