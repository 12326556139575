import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '../constants/router'
import store from '../store'
import router from '../router'

export function goToCurrentHomepage () {
  if (store.state.user.token && router.currentRoute.name !== ROUTE_DASHBOARD) {
    router.push({ name: ROUTE_DASHBOARD })
  }
  if (!store.state.user.token && router.currentRoute.name !== ROUTE_LOGIN) {
    router.push({ name: ROUTE_LOGIN })
  }
}
