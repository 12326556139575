<template>
  <div class="card card-body">
    <div class="card-content content is-medium">
      <h1 class="has-text-centered">
        {{ title }}
      </h1>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StaticContent',
  props: ['title']
}
</script>
