<template>
  <div id="app">
    <main>
      <Header />
      <UserHeader v-if="$store.state.user.token" />
      <div class="container mb-4 pb-4">
        <router-view/>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import UserHeader from './components/UserHeader'
import { getItemStorage } from './helpers/localStorage'
import { USER_DATA } from './constants/localStorage'
import { SET_USER_DATA } from './constants/mutations'

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    UserHeader
  },
  async created () {
    const userData = getItemStorage(USER_DATA)
    if (userData) {
      await this.$store.commit(SET_USER_DATA, userData)
    }
  }
}
</script>
<style lang="scss">
@import "scss/style";
</style>
