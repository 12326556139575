<template>
  <EventsLayout title="History" icon-classes="fa-history">
    <div class="loading-wrapper" v-if="loading">
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin">
      </b-icon>
    </div>
    <div v-show="!loading">
      <div class="tabs is-toggle is-fullwidth">
        <ul class="m-0">
          <li :class="{'is-active': $route.name === routes.ROUTE_HISTORY_RECEIVING}">
            <a @click="goToTab(routes.ROUTE_HISTORY_RECEIVING)">
              <span class="icon"><i class="fas fa-sign-in-alt fa-rotate-90" aria-hidden="true"></i></span>
              <span>Receiving</span>
            </a>
          </li>
          <li :class="{'is-active': $route.name === routes.ROUTE_HISTORY_DELIVERY}">
            <a @click="goToTab(routes.ROUTE_HISTORY_DELIVERY)">
              <span class="icon"><i class="fas fa-sign-out-alt fa-rotate-270" aria-hidden="true"></i></span>
              <span>Delivery</span>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <router-view/>
      </div>
    </div>
  </EventsLayout>
</template>

<script>
import EventsLayout from '../components/pages/EventsLayout'
import * as routes from '../constants/router'
import { GET_PARCELS_ACTION } from '../constants/actions'
export default {
  name: 'History',
  components: {
    EventsLayout
  },
  data: () => {
    return {
      routes,
      loading: true
    }
  },
  methods: {
    goToTab (name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name })
      }
    }
  },
  async mounted () {
    await this.$store.dispatch(GET_PARCELS_ACTION)
    this.loading = false
    if (this.$route.name === routes.ROUTE_HISTORY) {
      this.$router.replace({ name: routes.ROUTE_HISTORY_RECEIVING })
    }
  }
}
</script>
<style lang="scss" scoped>
.loading-wrapper{
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
