export const handleMultipleValues = (readFileArray, array) => {
  if (!array) {
    return
  }
  readFileArray.forEach((item, index) => {
    if (index === 0 && !array[array.length - 1].value) {
      array.pop()
      array.push({ value: item })
      return
    }
    if (item.length > 0) {
      array.push({ value: item })
    }
  })
  if (array.length >= 1) {
    array.push({ value: '' })
  }
}

export const sortItemsInFile = fileItems => {
  if (!fileItems.length) {
    return []
  }
  const skus = []
  const others = []
  fileItems.forEach(data => {
    if (/^\(01\).+/.test(data)) {
      skus.push(data)
    } else {
      others.push(data)
    }
  })

  return [skus, others]
}
