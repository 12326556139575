<template>
  <footer class="footer">
    <div class="container">
      <div class="content has-text-centered">
        <div class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-center">
          <router-link class="button is-ghost" :to="{ name: routes.aboutUs}"><b>About Us</b></router-link>
          <router-link class="button is-ghost" :to="{ name: routes.tac}"><b>Terms and Conditions</b></router-link>
          <a href="mailto:example@example.com" class="button is-ghost"><b>Contact</b></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ROUTE_ABOUT_US, ROUTE_TERMS_AND_CONDITIONS } from '../constants/router'
export default {
  name: 'Footer',
  data: () => {
    return {
      routes: {
        tac: ROUTE_TERMS_AND_CONDITIONS,
        aboutUs: ROUTE_ABOUT_US
      }
    }
  }
}
</script>
