<template>
  <div>
    <div class="api-error-box" v-if="errorMessageFromApi" v-html="errorMessageFromApiParsed" />
    <b-modal v-model="showErrorModal" :width="760" scroll="keep" :can-cancel="['x',  'escape', 'outside']">
      <div class="card">
        <div class="card-content">
          <h1>Validation errors</h1>
          <p><b>Please fix the following and re-submit your delivery:</b></p>
          <p v-html="errorMessageFromApiParsed" />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'ApiError',
  props: ['errorMessageFromApi'],
  data () {
    return {
      showErrorModal: false
    }
  },
  computed: {
    errorMessageFromApiParsed () {
      const message = this.errorMessageFromApi
      return message && message.replaceAll(',', ',<br>')
    }
  },
  watch: {
    errorMessageFromApi (newVal) {
      if (newVal) {
        this.showErrorModal = true
      }
    }
  }
}
</script>
<style lang="scss">
  .api-error-box{
    position: fixed;
    bottom: 5px;
    right: 5px;
    max-width: 500px;
    border-radius: 8px;
    background-color: #f58b8b;
    padding: 10px 20px;
    color: #820606;
    z-index: 9999999999999;
    user-select: none;
  }
</style>
