<template>
  <EventsLayout title="Success">
    <div class="content has-text-centered">
      <i class="fas fa-check-circle fa-8x has-text-success"></i>
      <h1>Success</h1>
      <p>{{ eventType }} event send correctly!</p>
    </div>
  </EventsLayout>
</template>

<script>
import EventsLayout from '../components/pages/EventsLayout'

export default {
  name: 'SuccessPage',
  props: ['eventType'],
  components: { EventsLayout }
}
</script>
