<template>
  <EventsLayout title="Receiving Event" icon-classes="fa-sign-in-alt fa-rotate-90">
    <form action="">
      <h2>I confirm receiving of the following shipment:</h2>
      <validate-input
        label="Shipment number"
        ref="trackingNumber"
        v-model="form.eventDetails.deliverySlipNo"
        rules="required"
        maxlength="50"
      />
      <validate-input
        label="Sender Company Name"
        ref="senderCompanyName"
        v-model="form.senderName"
        rules="required"
        maxlength="50"
      />
      <h2>The shipment consists of:</h2>
      <DragAndDrop class="mb-4" :array="form.eventDetails.skuList" @fileread="text => handleFileRead(text)" />
      <b-field>
        <b-checkbox v-model="isSku">Identify by single SKUs:</b-checkbox>
      </b-field>
      <div class="field" v-if="isSku">
        <InputMultiple rules="" label="Enter SKU:" :dataArray="form.eventDetails.skuList" placeholder="Enter SKU" placeholder-input="XXXXXXXXXXXXX" :firstRequired="isSku" ref="skuInputs"/>
      </div>
      <b-field>
        <b-checkbox v-model="isPalette">Identify by Palette/SSCC number:</b-checkbox>
      </b-field>
      <div v-if="isPalette">
        <InputMultiple label="Enter Palette/SSCC Numbers ID:" :dataArray="form.eventDetails.palettList" placeholder="Enter Palette/SSCC Number ID" placeholder-input="XXXXXXXXXXXXX" rules="" ref="pallettInputs" :firstRequired="isPalette" />
      </div>
      <span class="help is-danger mb-3" v-show="!isSku && !isPalette">SKU or Palette/SSCC list is required!</span>
      <div class="is-flex is-align-items-center is-justify-content-space-between actions">
        <div class="buttons mb-0">
          <button @click="submitForm" class="button is-link">Send</button>
          <b-button @click="goBack" type="is-ghost">Cancel</b-button>
        </div>
      </div>
    </form>
    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
    <ApiError :errorMessageFromApi="errorMessageFromApi" />
  </EventsLayout>
</template>

<script>
import EventsLayout from '../components/pages/EventsLayout'
import DragAndDrop from '../components/DragAndDrop'
import { ROUTE_SUCCESS } from '../constants/router'
import { getISODate, getTimeOffset } from '../helpers/date'
import { POST_EVENT_RECEIVING } from '../constants/actions'
import ValidateInput from '../components/ValidateInput'
import InputMultiple from '../components/InputMultiple'
import ApiError from '../components/ApiError'
import { handleMultipleValues, sortItemsInFile } from '../helpers/dataParse'
import highlightInvalidInputs from '../helpers/invalidInputHighlighter.js'
const model = {
  value: ''
}

export default {
  name: 'ReceivingEvent',
  components: {
    EventsLayout,
    DragAndDrop,
    ValidateInput,
    InputMultiple,
    ApiError
  },
  data: () => {
    return {
      isSku: true,
      isPalette: false,
      loading: false,
      errorMessageFromApi: null,
      form: {
        senderName: '',
        dealerCountryIso: null,
        event: 'receivingEvent',
        eventDetails: {
          eventTime: null,
          eventTimeZoneOffset: null,
          palettList: [{ ...JSON.parse(JSON.stringify(model)) }],
          skuList: [{ ...JSON.parse(JSON.stringify(model)) }],
          deliverySlipNo: ''
        }
      }
    }
  },
  mounted () {
    this.form.dealerCountryIso = this.$store.state.user.companies.items[0].company_country
  },
  methods: {
    submitForm (e) {
      e.preventDefault()
      if (!this.isValid()) {
        return
      }
      this.sendEvent()
    },
    handleFileRead (arr) {
      const sorted = sortItemsInFile(arr)
      if (sorted[1].length > 0) {
        handleMultipleValues(sorted[1], this.form.eventDetails.palettList)
        this.isPalette = true
      }
      if (sorted[0].length > 0) {
        handleMultipleValues(sorted[0], this.form.eventDetails.skuList)
        this.isSku = true
      }
    },
    isValid () {
      let errors = false
      this.$refs.senderCompanyName.validate()
      this.$refs.trackingNumber.validate()
      if (!this.form.senderName || this.form.senderName.length === 0) {
        errors = true
      }
      if (!this.form.eventDetails.deliverySlipNo || this.form.eventDetails.deliverySlipNo === 0) {
        errors = true
      }
      if (this.isSku && this.form.eventDetails.skuList[0].value.length === 0) {
        this.$refs.skuInputs.blurInputs()
        errors = true
      }

      if (!this.isSku && !this.isPalette) {
        errors = true
      }

      if (this.isPalette && this.form.eventDetails.palettList[0].value.length === 0) {
        this.$refs.pallettInputs.blurInputs()
        errors = true
      }

      // this.form.eventDetails.skuList.forEach(item => {
      //   if (item.value.length > 0 && !(/^\(01\).+/.test(item.value))) {
      //     errors = true
      //   }
      // })

      return !errors
    },
    async sendEvent () {
      this.errorMessageFromApi = null
      const currentDate = new Date()
      this.form.eventDetails.eventTime = getISODate(currentDate)
      this.form.eventDetails.eventTimeZoneOffset = getTimeOffset(currentDate)
      try {
        this.loading = true
        const newForm = JSON.parse(JSON.stringify(this.form))
        if (!this.isSku) {
          newForm.eventDetails.skuList = []
        }
        if (!this.isPalette) {
          newForm.eventDetails.palettList = []
        }
        await this.$store.dispatch(POST_EVENT_RECEIVING, newForm)
        this.$router.replace({ name: ROUTE_SUCCESS, params: { eventType: 'Receiving' } })
      } catch (err) {
        // console.log(err)
        if (err && err.status === 400) {
          this.errorMessageFromApi = err.data.error
          try {
            highlightInvalidInputs(this.errorMessageFromApi, this.$refs.skuInputs.$children)
          } catch (error) {
            return
          }
          return
        }
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Something went wrong... Please try again later.',
          position: 'is-bottom',
          type: 'is-danger'
        })
      } finally {
        this.loading = false
      }
    },
    handleSIGTIN (array) {
      this.form.sigtin = array[0]
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
