<template>
  <div class="card card-body">
    <div
      class="content is-align-items-start is-block is-flex is-justify-content-center is-relative mb-0 pb-4 pl-5 pr-5 pt-4">
      <a href="#" @click="goBack" class="back-button has-text-primary"> <i class="fa-chevron-left fas"></i> <b>Back</b> </a>
      <h1 class="has-text-primary is-align-items-center is-flex m-0"><i v-if="iconClasses" :class="['fas', 'mr-3', iconClasses]"></i> <b>{{ title }}</b>
      </h1>
    </div>
    <div class="card-content">
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventLayout',
  props: ['title', 'iconClasses'],
  methods: {
    goBack (e) {
      e.preventDefault()
      this.$router.back()
    }
  }
}
</script>
