import dayjs from 'dayjs'

export function formatDate (date) {
  return dayjs(date).format('DD.MM.YYYY')
}

export function getISODate (date) {
  return dayjs(date).toISOString().split('.')[0] + 'Z'
}

export function getTimeOffset (date) {
  const off = date.getTimezoneOffset()
  const absoff = Math.abs(off)
  return (off > 0 ? '-' : '+') + Math.floor(absoff / 60).toFixed(0).padStart(2, '0') + ':' + (absoff % 60).toString().padStart(2, '0')
}
