<template>
  <StaticContent title="Terms and conditions">
    <h2>1. Data protection at a glance</h2>
    <h3>General information</h3>
    <p>
      The following information provides a simple overview of what happens to your personal data when you visit our website. Personal data is any data by which you can be personally identified. For detailed information on the subject of data protection, please refer to our data protection declaration listed below this text.
    </p>
    <h3>
      Data collection on our website
    </h3>
    <p>
      Who is responsible for data collection on this website?<br>
      <br>
      Data processing on this website is carried out by the website operator. You can find his contact details in the imprint of this website.
    </p>
    <h3>
      How do we collect your data?
    </h3>
    <p>
      On the one hand, your data is collected by you providing it to us. Other data is collected automatically by our IT systems when you visit the website. This is mainly technical data (e.g. internet browser, operating system or time of page view). This data is collected automatically as soon as you enter our website.
    </p>
    <h3>
      What do we use your data for?
    </h3>
    <p>
      Some of the data is collected in order to ensure error-free provision of the website. Other data may be used to analyse your user behaviour.
    </p>
    <h3>
      What rights do you have regarding your data?
    </h3>
    <p>
      You have the right to receive information free of charge at any time about the origin, recipient and purpose of your stored personal data. You also have the right to demand the correction, blocking or deletion of this data. You can contact us at any time at the address given in the imprint for this purpose and for further questions on the subject of data protection. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.
    </p>
    <h2>
      2. General notes and obligatory information
    </h2>
    <h3>
      Data protection
    </h3>
    <p>
      The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.<br>
      <br>
      When you use this website, various personal data are collected. Personal data is data by which you can be personally identified. This Privacy Policy explains what information we collect and how we use it. It also explains how and for what purpose this is done.<br>
      <br>
      We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail) can have security gaps. Complete protection of data against access by third parties is not possible.<br>
      <br>
      Note on the responsible office
    </p>
    <h3>
      The responsible party for data processing on this website is:
    </h3>
    <p>
      Josera petfood GmbH & Co. KG<br>
      Industriegebiet Süd<br>
      63924 Kleinheubach<br>
      Germany<br>
      Phone: <a href="tel:+499379400">+49 (0) 9371 940-0</a><br>
      E-mail: <a href="mailto:josera@josera.de">josera@josera.de</a><br>
      <br>
      The responsible body is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, e-mail addresses or similar).
    </p>
    <h3>
      Withdrawal of your consent to data processing
    </h3>
    <p>
      Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. All you need to do is send us an informal e-mail. The legality of the data processing carried out until the revocation remains unaffected by the revocation.<br>
      <br>
      Right to object to data collection in special cases and to direct marketing (Art. 21 DSGVO)<br>
      <br>
      If data processing is based on Art. 6(1)(e) or (f) DSGVO, you have the right to object to the processing of your personal data at any time on grounds relating to your particular situation; this also applies to profiling based on these provisions. The respective legal basis on which processing is based can be found in this data protection declaration. If you object, we will no longer process your personal data concerned unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves the purpose of asserting, exercising or defending legal claims (objection under Article 21(1) of the GDPR).<br>
      <br>
      If your personal data are processed for the purpose of direct marketing, you have the right to object at any time to the processing of personal data concerning you for the purpose of such marketing; this also applies to profiling insofar as it is related to such direct marketing. If you object, your personal data will subsequently no longer be used for the purpose of direct advertising (objection pursuant to Art. 21 (2) DSGVO).<br>
      <br>
      We only transfer personal data within the ERBACHER the food family* group of companies or to third parties if this is necessary in the context of processing the contract, for example to the companies entrusted with the delivery of the goods or to the credit institution entrusted with the processing of payments.<br>
      <br>
      *Josera GmbH & Co. KG, Josera petfood GmbH & Co. KG, foodforplanet GmbH & Co. KG, Erbacher Food Intelligence GmbH & Co. KG, Josera Erbacher GmbH & Co. KG, Greensale GmbH & Co. KG
    </p>
    <h3>
      Right of complaint to the competent supervisory authority
    </h3>
    <p>
      In the event of violations of data protection law, the person concerned has the right to lodge a complaint with the competent supervisory authority. The competent supervisory authority in matters of data protection law is the State Data Protection Commissioner of the federal state in which our company is based. A list of data protection officers and their contact details can be found at the following link:
      <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
    </p>
    <h3>
      Right to data portability
    </h3>
    <p>
      You have the right to have data that we process automatically on the basis of your consent or in fulfilment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible.
    </p>
    <h3>
      SSL or TLS encryption
    </h3>
    <p>
      For security reasons and to protect the transmission of confidential content, such as orders or enquiries that you send to us as the site operator, this site uses SSL or TLS encryption. You can recognise an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.<br>
      <br>
      If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.
    </p>
    <h3>
      Information, blocking, deletion
    </h3>
    <p>
      Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipient and the purpose of the data processing and, if applicable, the right to correction, blocking or deletion of this data. You can contact us at any time at the address given in the imprint for this purpose and for further questions on the subject of personal data.
    </p>
    <h2>
      3. Data protection officer
    </h2>
    <p>
      We have appointed the following external service provider as data protection officer for our company:<br>
      <br>
      atarax GmbH & Co. KG<br>
      Dr.-Dassler-Strasse 57<br>
      D-91074 Herzogenaurach<br>
      <a href="http://atarax.de" target="_blank"></a><br>
      Phone: <a href="tel:+49093719400">+49(0)9371 940-0</a><br>
      E-mail: <a href="mailto:datenschutz@josera-erbacher.de" target="_blank">datenschutz@josera-erbacher.de</a><br>
      <br>
      Your rights as a data subject
      <br>
      First of all, we would like to inform you at this point about your rights as a data subject. These rights are standardised in Art. 15 -22 EU-DS-GVO. This includes:
    </p>
    <ul>
      <li>The right to information (Art. 15 EU-DS-GVO),</li>
      <li>The right to erasure (Art. 17 EU-DS-GVO),</li>
      <li>The right to rectification (Art. 16 EU-DS-GVO),</li>
      <li>The right to data portability (Art. 20 EU GDPR),</li>
      <li>The right to restriction of data processing (Art. 18 EU-DS-GVO),</li>
      <li>The right to object to data processing (Art. 21 EU-DS-GVO).</li>
    </ul>
    <p>
      To assert these rights, please contact:<br>
      <br>
      atarax group of companies<br>
      <br>
      E-mail: <a href="mailto:datenschutz@josera-erbacher.de">datenschutz@josera-erbacher.de</a><br>
      <br>
      The same applies if you have questions about data processing in our company. You also have the right to lodge a complaint with a data protection supervisory authority.
    </p>
    <h2>
      4. Data collection on our website
    </h2>
    <h3>
      Cookies
    </h3>
    <p>
      Some of the Internet pages use so-called cookies. Cookies do not damage your computer and do not contain viruses. Cookies serve to make our offer more user-friendly, effective and secure. Cookies are small text files that are stored on your computer and saved by your browser.<br>
      <br>
      Most of the cookies we use are so-called "session cookies". They are automatically deleted at the end of your visit. Other cookies remain stored on your end device until you delete them. These cookies enable us to recognise your browser on your next visit.<br>
      <br>
      You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If you deactivate cookies, the functionality of this website may be limited.<br>
      <br>
      Cookies that are required to carry out the electronic communication process or to provide certain functions that you have requested (e.g. shopping cart function) are stored on the basis of Art. 6 para. 1 lit. f DSGVO. The website operator has a legitimate interest in storing cookies for the technically error-free and optimised provision of its services. Insofar as other cookies (e.g. cookies to analyse your surfing behaviour) are stored, these are dealt with separately in this data protection declaration.
    </p>
    <h3>
      Server log files
    </h3>
    <p>
      The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:
    </p>
    <ul>
      <li>browser type and browser version</li>
      <li>Operating system used</li>
      <li>referrer URL</li>
      <li>Host name of the accessing computer</li>
      <li>Time of the server request</li>
      <li>IP address</li>
    </ul>
    <p>
      This data is not merged with other data sources.<br>
      <br>
      The basis for data processing is Art. 6 para. 1 lit. b DSGVO, which permits the processing of data for the fulfilment of a contract or pre-contractual measures.
    </p>
    <h3>
      Contact form
    </h3>
    <p>
      If you send us enquiries via the contact form, your details from the enquiry form, including the contact data you have provided there, will be stored by us for the purpose of processing the enquiry and in the event of follow-up questions. We do not pass on this data without your consent.<br>
      The processing of the data entered in the contact form is therefore based exclusively on your consent (Art. 6 para. 1 lit. a DSGVO). You can revoke this consent at any time. For this purpose, an informal communication by e-mail to us is sufficient. The legality of the data processing operations carried out until the revocation remains unaffected by the revocation.<br>
      The data you entered in the contact form will remain with us until you request us to delete it, revoke your consent to store it or the purpose for storing the data no longer applies (e.g. after we have completed processing your request). Mandatory legal provisions - in particular retention periods - remain unaffected.
    </p>
    <h3>
      Processing of data (customer and contract data)
    </h3>
    <p>
      We collect, process and use personal data only to the extent that it is necessary for the establishment, content or amendment of the legal relationship (inventory data). This is done on the basis of Art. 6 (1) lit. b DSGVO, which permits the processing of data for the fulfilment of a contract or pre-contractual measures. We collect, process and use personal data about the use of our Internet pages (usage data) only insofar as this is necessary to enable the user to use the service or to bill the user.<br>
      <br>
      The collected customer data will be deleted after completion of the order or termination of the business relationship. Statutory retention periods remain unaffected.
    </p>
  </StaticContent>
</template>

<script>
import StaticContent from '../../components/pages/StaticContent'
export default {
  name: 'TermsAndConditions',
  components: {
    StaticContent
  }
}
</script>
