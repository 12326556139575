export const getItemStorage = (key) => {
  const item = localStorage.getItem(key)
  if (item && (item[0] === '{' || item[0] === '[')) {
    return JSON.parse(item)
  }

  return item
}
export const setItemStorage = (key, value) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  localStorage.setItem(key, value)
}

export const removeItemStorage = key => {
  localStorage.removeItem(key)
}
