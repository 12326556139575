<template>
  <div>
    <div v-if="$store.state.history.deliveryEvent.length <= 0" class="has-text-centered">
      No history for Delivery Event!
    </div>
    <table v-if="$store.state.history.deliveryEvent.length > 0" class="table">
      <thead>
      <tr>
        <th style="width: 120px;">Date</th>
        <th>Receiver</th>
        <th>Palette ID</th>
        <th>SGTINS</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in $store.state.history.deliveryEvent" v-bind:key="index">
          <td>{{ formatDate(item.payload.eventDetails.eventTime) }}</td>
          <td>{{ item.payload.eventDetails.customer }}</td>
          <td class="list-pallets">
            <div v-for="(palette, index) in item.payload.eventDetails.aggregationList" v-bind:key="'palette-'+index">
              {{ palette.ssccNumber }}
              <button type="button" class="button is-primary is-small ml-2" v-if="palette.skuList.length" title="Show all" @click="openModalSscc(palette.skuList, palette.ssccNumber)">Show all</button>
            </div>
            <div v-for="(palette, index) in item.payload.eventDetails.palletList" v-bind:key="'palette-single-'+index">
              {{ palette }}
            </div>
          </td>
          <td>
            <button type="button" class="button is-primary is-small" v-if="item.payload.eventDetails.skuList.length" title="Show all" @click="openModal(item.payload.eventDetails.skuList)">Show all</button>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal v-model="isModalOpen" :width="760" scroll="keep" :on-cancel="closeModal" :can-cancel="['x', 'escape', 'outside']">
      <div class="card" v-if="skuArray">
        <div class="card-content">
          <h2>Sgtins in:</h2>
          <ul>
            <li v-for="(item, index) in skuArray" v-bind:key="'skuList-'+index">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isModalOpenSscc" :width="760" scroll="keep" :on-cancel="closeModalSscc" :can-cancel="['x', 'escape', 'outside']">
      <div class="card" v-if="ssccNumbers">
        <div class="card-content">
          <h2>Sgtins in pallete {{paletteName}}:</h2>
          <ul>
            <li v-for="(item, index) in ssccNumbers" v-bind:key="'ssccNumbers-'+index">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { formatDate } from '../../helpers/date'

export default {
  name: 'DeliveryHistory',
  data: () => {
    return {
      isModalOpenSscc: false,
      isModalOpen: false,
      paletteName: null,
      skuArray: [],
      ssccNumbers: []
    }
  },
  methods: {
    formatDate (date) {
      return formatDate(date)
    },
    openModal (array) {
      this.skuArray = array
      this.isModalOpen = true
    },
    closeModal () {
      this.skuArray = []
    },
    openModalSscc (array, palletId) {
      this.ssccNumbers = array
      this.paletteName = palletId
      this.isModalOpenSscc = true
    },
    closeModalSscc () {
      this.paletteName = null
      this.skuArray = []
    }
  }
}
</script>
<style lang="scss">
.list-pallets{
  div{
    display: flex;
    &:not(:last-of-type){
      margin-bottom: 16px;
    }
  }
}
</style>
