export const API_LOGIN = {
  url: '/login'
}
export const API_GET_PARCELS = {
  url: (dealerID) => `${dealerID}/parcels/history`
}

export const API_POST_DELIVERY_EVENT = {
  url: (dealerID) => `${dealerID}/parcels/deliver`
}

export const API_POST_RECEIVING_EVENT = {
  url: (dealerID) => `${dealerID}/parcels/receive`
}
