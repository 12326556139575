<template>
  <div class="loading-screen">
    <b-loading :active="true" :is-full-page="false" class="no-bg">
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </div>
</template>

<script>
import { ROUTE_LOGIN, ROUTE_DASHBOARD } from '../constants/router'
export default {
  name: 'InitScreen',
  mounted () {
    if (this.$store.state.user) {
      this.$router.replace({ name: ROUTE_DASHBOARD })
    } else {
      this.$router.replace({ name: ROUTE_LOGIN })
    }
  }
}
</script>

<style lang="scss">
  .loading-screen{
    min-height: 400px;
  }
</style>
