<template>
  <EventsLayout title="Preview">
    Preview
    <div class="buttons actions">
      <button @click="send()"  class="button is-primary">
        Send
      </button>
      <button @click="goBack()" class="button is-primary is-light">
        Back
      </button>
    </div>
  </EventsLayout>
</template>

<script>
import EventsLayout from '../components/pages/EventsLayout'
import { ROUTE_SUCCESS } from '../constants/router'

export default {
  name: 'PreviewScreen',
  components: { EventsLayout },
  methods: {
    goBack () {
      this.$router.back()
    },
    send () {
      this.$router.push({ name: ROUTE_SUCCESS })
    }
  }
}
</script>
