export const ROUTE_DASHBOARD = 'ROUTE_DASHBOARD'
export const ROUTE_TERMS_AND_CONDITIONS = 'ROUTE_TERMS_AND_CONDITIONS'
export const ROUTE_ABOUT_US = 'ROUTE_ABOUT_US'
export const ROUTE_404 = 'ROUTE_404'
export const ROUTE_LOGIN = 'ROUTE_LOGIN'
export const ROUTE_INIT_SCREEN = 'ROUTE_INIT_SCREEN'
export const ROUTE_HISTORY = 'ROUTE_HISTORY'
export const ROUTE_HISTORY_RECEIVING = 'ROUTE_HISTORY_RECEIVING'
export const ROUTE_HISTORY_DELIVERY = 'ROUTE_HISTORY_DELIVERY'
export const ROUTE_DELIVERY_EVENT = 'ROUTE_DELIVERY_EVENT'
export const ROUTE_RECEIVING_EVENT = 'ROUTE_RECEIVING_EVENT'
export const ROUTE_PREVIEW = 'ROUTE_PREVIEW'
export const ROUTE_SUCCESS = 'ROUTE_SUCCESS'
