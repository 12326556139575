<template>
  <div ref="dnd" @click="clickHandler" :class="['dnd', isDragEnter ? 'is-enter' : null]">
    <b-icon
      pack="fas"
      custom-class="fa-3x"
      icon="file-upload">
    </b-icon>
    <span v-show="!isDragEnter">Upload file with scanned SKU/Pallette codes</span>
    <span v-show="isDragEnter">Drop file here</span>
    <input ref="dnd-file-input" type="file" style="display: none;">
  </div>
</template>

<script>
export default {
  name: 'DragAndDrop',
  data: () => {
    return {
      isDragEnter: false
    }
  },
  mounted () {
    const dnd = this.$refs.dnd
    dnd.addEventListener('dragover', this.dragEnter)
    dnd.addEventListener('dragleave', this.dragLeave)
    dnd.addEventListener('drop', this.dragDrop);
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dnd.addEventListener(eventName, preventDefaults, false)
    })

    function preventDefaults (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const dndInput = this.$refs['dnd-file-input']

    dndInput.addEventListener('change', () => {
      this.handleFile(dndInput.files[0])
      dndInput.value = null
    })
  },
  methods: {
    clickHandler () {
      this.$refs['dnd-file-input'].click()
    },
    dragEnter () {
      if (!this.isDragEnter) {
        this.isDragEnter = true
      }
    },
    dragLeave () {
      this.isDragEnter = false
    },
    dragDrop (e) {
      e.preventDefault()
      this.isDragEnter = false
      const dt = e.dataTransfer
      const files = dt.files
      this.handleFile(files[0])
    },
    handleFile (file) {
      if (file.type !== 'text/plain') {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Only accepts txt files!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        const text = reader.result.split(/\r?\n/)
        const textArr = this.breakOnASCIICharacter(text)
        this.$emit('fileread', textArr)
      }
      reader.readAsText(file)
    },
    breakOnASCIICharacter (text) {
      const textArr = []
      text.forEach(item => {
        if (item.includes('\u001d')) {
          const newItem = item.split('\u001d') // special character when scanning
          newItem[1] = this.addBrackets(newItem[1])
          textArr.push(newItem.join(''))
        } else {
          textArr.push(item)
        }
      })
      return textArr
    },
    addBrackets (data) {
      if (data[0] !== '(') {
        const dataArray = data.split('')
        dataArray.splice(0, 0, '(')
        dataArray.splice(3, 0, ')')
        return dataArray.join('')
      }
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
  .dnd{
    width: 100%;
    height: 150px;
    border: 2px solid #dbdbdb;
    border-radius: 0.375em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    .icon{
      height: 48px;
      margin-bottom: 8px;
    }
    &.is-enter{
      border-color: lighten(#DF001C, 45%);
      background-color: lighten(#DF001C, 55%)
    }
    span{
      opacity: .5;
    }
  }
</style>
